import React from "react"
import PropTypes from 'prop-types';

const mapStyle = [
  {
    "featureType": "administrative",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "landscape",
    "stylers": [
      {
        "hue": "#0066ff"
      },
      {
        "saturation": 74
      },
      {
        "lightness": 100
      },
      {
        "visibility": "simplified"
      }
    ]
  },
  {
    "featureType": "poi",
    "stylers": [
      {
        "visibility": "simplified"
      }
    ]
  },
  {
    "featureType": "road",
    "stylers": [
      {
        "visibility": "simplified"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "stylers": [
      {
        "saturation": -85
      },
      {
        "lightness": 61
      },
      {
        "visibility": "off"
      },
      {
        "weight": 0.6
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "road.local",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "transit",
    "stylers": [
      {
        "visibility": "simplified"
      }
    ]
  },
  {
    "featureType": "water",
    "stylers": [
      {
        "color": "#5f94ff"
      },
      {
        "lightness": 26
      },
      {
        "gamma": 5.86
      },
      {
        "visibility": "simplified"
      }
    ]
  }
];

class GMap extends React.Component {
  componentDidUpdate() {
    if (this.props.mapIsReady) {
      this.map = new window.google.maps.Map(document.getElementById(this.props.id), {
        center: {lat: this.props.lat, lng: this.props.long},
        zoom: 15,
        mapTypeId: 'roadmap',
        styles: mapStyle
      });
      this.marker = new window.google.maps.Marker({
        position: {lat: this.props.lat, lng: this.props.long},
        map: this.map,
        title: 'Test'
      });
    }
  }

  render() {
    return (
      <div id={this.props.id} />
    );
  }
}

GMap.propTypes = {
  lat: PropTypes.number,
  long: PropTypes.number,
  id: PropTypes.string,
  mapIsReady: PropTypes.bool
};

export default GMap
