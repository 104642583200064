import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import GMap from "../components/GMap"

class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mapIsReady: false,
    };
  }

  componentDidMount() {
    const ApiKey = 'AIzaSyBxFOEyy5ZESYcYXoh2T-RpsT9qePTff7o';
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${ApiKey}`;
    script.async = true;
    script.defer = true;
    script.addEventListener('load', () => {
      this.setState({ mapIsReady: true });
    });

    document.body.appendChild(script);
  }

  render() {
    return (
      <Layout>
        <SEO title="Hochzeit von Sandra und Johannes" />
        <article>
          <h1>Grusswort</h1>
          Liebe Hochzeitsgäste,<br/>
          wir freuen uns auf die kirchliche Hochzeit und die Feier mit euch allen zusammen. Nachfolgend findet ihr einige Informationen zur Hochzeit, wie z.B. Google Map Links und Termine, die ihr in eure Kalender importieren könnt.
        </article>

        <article>
          <h1>Hochzeit in der Heilandskirche</h1>
          <p>
            Kalender Eintrag: <a href="/Hochzeit.ics">Hinzufügen zum Kalender</a>
          </p>
          <GMap lat={52.424651} long={13.096391} id="mapChurch" mapIsReady={this.state.mapIsReady} />
        </article>

        <article>
          <h1>Feier im Landleben Potsdam</h1>
          <p>
            Kalender Eintrag: <a href="/Feier.ics">Hinzufügen zum Kalender</a>
          </p>
          <GMap lat={52.452284} long={13.106338} id="mapLocation" mapIsReady={this.state.mapIsReady} />
        </article>
      </Layout>
    )
  }
}

export default IndexPage
